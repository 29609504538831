export const mapItems = ({
  itemsByModule,
  activeModules,
  favoriteHated,
  belovedOnly = false,
}) => {
  let data = Object.entries(itemsByModule).flatMap(([moduleId, items]) => {
    if (!activeModules.includes(Number(moduleId))) {
      return [];
    }
    const projectsExcludedFromModule = Object.keys(
      favoriteHated.excludedFromModule
    ).filter(key =>
      favoriteHated.excludedFromModule[key].includes(parseInt(moduleId))
    );
    return items.filter(
      item => !projectsExcludedFromModule.includes(item.id.toString())
    );
  });

  // const out = [...favoriteHated.favorite];
  const out = [];
  data.push(...favoriteHated.favorite);
  data.forEach(item => {
    const isDuplicate = out.find(i => Number(i.id) === Number(item.id));
    if (isDuplicate) {
      return;
    }

    const isHated = favoriteHated.hated.find(
      i => Number(i) === Number(item.id)
    );
    if (isHated) {
      return;
    }
    if (belovedOnly) {
      const isBeloved = favoriteHated.beloved.find(
        i => Number(i) === Number(item.id)
      );
      if (isBeloved) {
        out.push(item);
      }
    } else {
      out.push(item);
    }
  });
  out.sort((a, b) => (a.date > b.date ? -1 : 1));
  return out;
};
