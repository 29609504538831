<script>
import { mapItems } from '@/views/Monitoring/util/mapItems';

export default {
  name: 'ListSettings',
  data() {
    return {
      belovedItems: [],
      belovedOnly: false,
      renderSwitch: true,
    };
  },
  watch: {
    belovedOnly: function(v) {
      if (v && this.$store.getters['user/isLoggedIn']) {
        const favoriteHatedRaw = this.$store.state.list.favoriteHated;
        let itemsByModule = {};

        for (let moduleId in this.$store.state.list.itemsByModule) {
          itemsByModule = {
            ...itemsByModule,
            [moduleId]: this.$store.state.list.itemsByModule[moduleId].map(
              function(item) {
                return {
                  id: item.pk,
                  title: item.title,
                  slug: item.slug,
                  stage: item.stage_primary,
                  stageSecondary: item.stage_secondary,
                  date: new Date(item.last_change),
                  open: false,
                };
              }
            ),
          };
        }

        let favoriteHated = {
          favorite: [],
          hated: favoriteHatedRaw ? favoriteHatedRaw.hateful : [],
          excludedFromModule: favoriteHatedRaw
            ? favoriteHatedRaw.excluded_from_module
            : [],
          beloved:
            favoriteHatedRaw && favoriteHatedRaw.beloved
              ? favoriteHatedRaw.beloved
              : [],
        };
        this.belovedItems = mapItems({
          itemsByModule: itemsByModule,
          activeModules: Object.keys(
            this.$store.state.list.itemsByModule
          ).map(key => parseInt(key)),
          favoriteHated: favoriteHated,
          belovedOnly: true,
        });
        this.$emit('belovedItems', this.belovedItems);
      } else if (v && !this.$store.getters['user/isLoggedIn']) {
        this.belovedOnly = false;
        this.$store.commit('openGuestModal');
        this.renderSwitch = false;
        this.$nextTick(() => {
          this.renderSwitch = true;
        });
        this.$emit('belovedItems', null);
      } else {
        this.$emit('belovedItems', null);
      }
    },
  },
};
</script>

<template>
  <div class="modules">
    <div class="modules-beloved">
      <div>
        <span>pokaż tylko ulubione</span>
      </div>
      <base-switch
        v-if="renderSwitch"
        v-model="belovedOnly"
        name="belovedOnly"
        simple
        small
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.modules {
  margin-bottom: 20px;
  @include mq('tablet') {
    display: flex;
    justify-content: end;
  }
  .modules-beloved {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    font-size: 11px;
    letter-spacing: 0.2px;
    white-space: nowrap;
    color: $gray-3;
    position: relative;
    align-items: center;
    grid-gap: 10px;
    @include mq('tablet') {
      z-index: 10;
    }
  }
}
</style>
