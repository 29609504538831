export const europeanunionactsFilters = [
  {
    value: '',
    label: 'tytuł',
    name: 'title',
    type: 'text',
    highlightKeyword: 'title_keyword',
  },
  {
    value: '',
    label: 'tekst',
    name: 'text',
    type: 'text',
    keys: ['full_text_2d', 'full_text_w', 'full_text_m', 'full_text'],
    splitRequests: true,
    highlight: true,
  },
  {
    value: '',
    label: 'opis projektu',
    name: 'descriptions_filter',
    type: 'text',
    highlightKeyword: 'description_keyword',
  },
  {
    value: [],
    label: 'rodzaj aktu',
    name: 'act_type',
    type: 'select',
    options: [
      { value: 'R', label: 'rozporządzenie' },
      { value: 'D', label: 'dyrektywa' },
      { value: 'C', label: 'decyzja' },
      { value: 'O', label: 'inne' },
    ],
  },
  {
    value: '',
    label: 'numer wniosku',
    name: 'proposal_number',
    type: 'text',
  },
  {
    value: '',
    label: 'numer procedury',
    name: 'procedure_number',
    type: 'text',
  },
  {
    value: '',
    label: 'dyrekcja generalna',
    name: 'dg',
    type: 'text',
  },
  {
    value: '',
    label: 'komisja',
    name: 'commitee',
    type: 'text',
  },
  {
    value: '',
    label: 'sprawozdawca',
    name: 'raporteur',
    type: 'text',
  },
  {
    value: [],
    label: 'etap',
    name: 'stage_short',
    type: 'select',
    options: [
      { value: 'i1', label: 'w przygotowaniu' },
      { value: 'i2', label: 'mapa drogowa' },
      { value: 'i3', label: 'konsultacje publiczne' },
      { value: 'i4', label: 'projekt aktu' },
      { value: 'i5', label: 'przyjęcie przez Komisję' },
      { value: 'a1', label: 'prace nad wnioskiem w Komisji' },
      { value: 'p1', label: 'wniosek przyjęty przez Komisję' },
      { value: 'p2', label: 'debata w Radzie' },
      { value: 'p3', label: 'przyjęty przez Radę' },
      { value: 'o1', label: 'faza przygotowawcza w Parlamencie' },
      { value: 'o2', label: 'oczekuje na decyzję komitetu' },
      { value: 'o3', label: 'oczekuje na głosowanie w Parliamencie' },
      { value: 'o4', label: 'oczekuje na stanowisko Parliamentu w 1. czytaniu' },
      { value: 'o5', label: 'oczekuje na posiedzenie plenarne' },
      { value: 'o6', label: 'oczekuje na stanowisko Rady w 1. czytaniu w toku' },
      { value: 'o7', label: 'oczekuje na 2. czytanie w Parlamencie' },
      { value: 'o8', label: 'oczekuje na decyzję końcową' },
      { value: 'o9', label: 'oczekuje na decyzję Rady' },
      { value: 'oa', label: 'oczekuje na zwołanie budżetowej komisji pojednawczej' },
      { value: 'ob', label: 'zwołano budżetową komisję pojednawczą' },
      { value: 'oc', label: 'oczekiwanie na stanowisko Parlamentu' },
      { value: 'od', label: 'wstępne porozumienie między Parlamentem a Radą' },
      { value: 'oe', label: 'oczekuje na podpis' },
      { value: 'c1', label: 'oczekuje na publikację w Dzienniku Urzędowym' },
      { value: 'c2', label: 'zakończony' },
      { value: 'p4', label: 'ogłoszony' },
      { value: 'c3', label: 'obowiązuje' },
      { value: 'c4', label: 'odrzucony' },
      { value: 'r', label: 'odrzucony' },
      { value: 'w', label: 'odroczony lub wycofany' },
    ],
  },
  {
    value: [],
    label: 'status',
    name: 'status',
    type: 'select',
    options: [
      { value: 'A', label: 'prace w toku' },
      { value: 'F', label: 'obowiązuje' },
      { value: 'R', label: 'archiwalny' },
    ],
  },
];
